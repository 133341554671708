$spinner-size: 2rem;
$primary-color: #0284fa;
$bg-color: rgba(255, 255, 255, 0.85);

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  // background-color: $bg-color;
  z-index: 1000;

  // Bar
  .bar {
    background: $primary-color;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.25rem;
  }

  // Spinner {
  .spinner {
    position: fixed;
    z-index: 1031;
    top: 50%;
    right: 50%;
    /* display: block; */
    display: none;
    margin-top: -10px;
    margin-left: -10px;
  }

  .spinner-icon {
    width: 20px;
    height: 20px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
}

/* Fancy blur effect */
/* #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;
  
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  } */

/* Remove these to get rid of the spinner */
#nprogress #nprogress .nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
